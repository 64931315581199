import React, { useState } from 'react'
import { DotContent, Flex, HomeCall, HomeContent, HomeDot, HomePicture, StyledHome } from './Style'
import { Col, Image, Modal, Row } from 'antd'
import Home_Matrix from "../../../assets/images/Home_Matrix.png"
import Home_Pic from "../../../assets/images/Home_Pic.png"
import { Heading, ModalBody } from '../../NavBar/stylenav'

const HomePage = () => {

  // const [isOpen,setIsOpen] = useState(true);
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);


  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };


  const showModal = () => {
    setOpen(true);
  };

  return (
    <StyledHome id='Home'>
 
        <Row>
        
            <Col span={24} md={12}>
            <HomeContent>
                <img src={Home_Matrix}  preview={false} alt='pic'/>
            <h1>Aari Work Blouse <br/>Designs</h1><br/>
            <p className='head'>Aari world undertake Aari work, Hand work orders 
                from Boutiques, <br/>Tailor shops and Individual 
                customers at lowest rate in Market.</p><br/><br/>
                <DotContent>
               <Row >
            <Col span={24} md={5}>
            
              <Flex>
              <HomeDot />
              <p>&nbsp;&nbsp;Wedding </p>
              </Flex>
              </Col>
            {/* <Col span={24} md={5}>
            <Flex>
              <HomeDot />
              <p>&nbsp;&nbsp;Mehndi </p>
              </Flex>
            </Col> */}
            <Col span={24} md={5}>
            <Flex>
              <HomeDot />
              <p>&nbsp;&nbsp;Other Occations </p>
              </Flex>
            </Col>
        
           </Row>
           </DotContent>
        <HomeCall onClick={showModal}><p>Call us to know prices</p></HomeCall>
        </HomeContent>
            </Col>
           
      <Col span={24} md={12}>
        <HomePicture> 
                <Image src={Home_Pic} alt='pic' preview={false} style={{width:'100%'}} />
                </HomePicture>
            </Col>

        </Row>
        
           
      <Modal
      bodyStyle={{padding:'0'}}
      style={{padding: '0px'}}
   
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
         
        ]}
      >
        <ModalBody>

        <Heading>
            <h4>Contact Details</h4>
            
          </Heading>

<h1>Contact Us via : </h1> <br/>
<h2>Phone 1 :+91 8300882101 <br/>
    Phone 2: +91 9688532695 <br/>
    Email : aariworld97@gmail.com
    </h2><br/><br/>
    <p style={{textAlign:'center'}}>"Your Satisfaction is Our Priority "</p>

                          

   </ModalBody>
   </Modal>
    </StyledHome>
  )
}

export default HomePage